<template>
  <div class="commodityDetails flex flex-direction items-center flex-center">
    <img
      src="https://cdn.bubbleplan.cn/static/home/backPage.png"
      alt=""
      class="commodityDetails-back"
      @click="back()"
    />
    <div class="commodityDetails-detail flex">
      <img
        src="https://cdn.bubbleplan.cn/static/home/Vector683.png"
        alt=""
        class="commodityDetails-detail-jiao"
      />
      <div class="commodityDetails-detail-commodity-title">
        {{ product.activityName }}
      </div>
      <img :src="product.img2" alt="" class="commodityDetails-detail-img" />
      <div class="commodityDetails-detail-commodity flex">
        <div class="commodityDetails-detail-commodity-box">
          <!-- <div class="flex flex-between items-center">
            <div class="commodityDetails-detail-commodity-price">
              价格：{{ product.price }}RMB
            </div>
          </div> -->
          <div class="commodityDetails-detail-commodity-box-wwe">介绍</div>

          <div class="commodityDetails-detail-commodity-sellRemark">
            {{ product.sellRemark }}
          </div>
        </div>
        <div class="commodityDetails-detail-commodity-but flex">
          <!-- <div class="commodityDetails-detail-commodity-but-price">
            ￥{{ product.price }}
          </div> -->
          <div
            class="commodityDetails-detail-commodity-but-button"
            @click="$refs.paymentDetailsRef.paymentDetailsShow = true"
          >
            购买
          </div>
        </div>
      </div>
    </div>
    <div class="commodityDetails-text">
      <!-- {{ product.sellRemark }} -->
      <div>购买须知</div>
      <div>
        1.
        数字文创藏品为虚拟数字商品，而非实物，仅限年满18周岁的中国大陆实名认证用户购买。数字文创藏品的版权由发行方和原创者拥有，除另行获得版权拥有者书面同意外，用户不得将数字文创藏品用于任何商业用途。
      </div>
      <div>
        2.本商品的介绍、权益等信息均为发行方提供，《泡泡计划》仅作为发行方的销售渠道，不对介绍和权益内容作任何形式的担保。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字文创藏品进行炒卖、场外交易、欺诈或以任何其他非法方式进行使用。
      </div>
    </div>
    <paymentDetails
      :payType="payType"
      :commodityData="product"
      @purchaseButton="purchaseButton"
      ref="paymentDetailsRef"
    >
    </paymentDetails>
  </div>
</template>
<script>
import paymentDetails from "./components/paymentDetails.vue";
export default {
  components: {
    paymentDetails,
  },
  data() {
    return {
      secondsTimer: null,
      secondsTimer1: null,
      secondsTimer2: null,
      secondsTimer3: null,
      secondsTimer4: null,
      secondsTimer5: null,

      product: {},
      secondsNum: "",
      minutesNum: "",
      hoursNum: "",
      secondsNum1: "",
      minutesNum1: "",
      hoursNum1: "",
      contentVisible: false,
      payType: [],
    };
  },
  mounted() {
    this.product = this.$route.query;
    this.payType = this.product.payChannel.split(",");
    this.seconds(this.product.beforeBeginTime);
    this.minutes(this.product.beforeBeginTime);
    this.hours(this.product.beforeBeginTime);
    this.seconds1(this.product.beginTime);
    this.minutes1(this.product.beginTime);
    this.hours1(this.product.beginTime);
  },
  beforeDestroy() {
    clearInterval(this.secondsTimer);
    clearInterval(this.secondsTimer1);
    clearInterval(this.secondsTimer2);
    clearInterval(this.secondsTimer3);
    clearInterval(this.secondsTimer4);
    clearInterval(this.secondsTimer5);
  },

  methods: {
    purchaseButton(paymentType) {
      this.rushToPurchase(paymentType);
    },

    //首发购买
    async rushToPurchase(paymentType) {
      const params = {
        activityId: this.product.id,
        orderSource: "PC",
        // isPriorityPurchase: "0",
        payType: paymentType,
        buyCount: 1,
      };
      const { data, code } = await this.$ajax.rushToPurchase(params);
      if (code == 200) {
        if (data && paymentType == 1) {
          // location.href = data;
          window.open(data);
        } else {
          this.$message({
            message: data,
            type: "success",
          });
        }
      }
    },

    //时
    hours1(data) {
      if (data) {
        this.secondsTimer = setInterval(() => {
          let date;
          let nowTime = new Date().getTime();
          date = new Date(data).getTime();
          var times = (date * 1 - nowTime * 1) / 1000; //time是剩余时间总的秒数
          if (times > 0) {
            var d = parseInt((times / 60 / 60) % 24); //计算时
            this.hoursNum1 = this.padZero(d);
          } else {
            clearInterval(this.secondsTimer);
          }
          this.isBeginTime(this.product.beginTime);
        }, 1000);
      }
    },
    //分
    minutes1(data) {
      if (data) {
        this.secondsTimer1 = setInterval(() => {
          let date;
          let nowTime = new Date().getTime();
          date = new Date(data).getTime();
          var times = (date * 1 - nowTime * 1) / 1000; //time是剩余时间总的秒数
          if (times > 0) {
            var d = parseInt((times / 60) % 60); //计算分
            this.minutesNum1 = this.padZero(d);
          } else {
            clearInterval(this.secondsTimer1);
          }
          this.isBeginTime(this.product.beginTime);
        }, 1000);
      }
    },
    //秒
    seconds1(data) {
      if (data) {
        this.secondsTimer2 = setInterval(() => {
          let date;
          let nowTime = new Date().getTime();
          date = new Date(data).getTime();
          var times = (date * 1 - nowTime * 1) / 1000; //time是剩余时间总的秒数
          if (times > 0) {
            var d = parseInt(times % 60); //计算秒
            this.secondsNum1 = this.padZero(d);
          } else {
            clearInterval(this.secondsTimer2);
          }
          this.isBeginTime(this.product.beginTime);
        }, 1000);
      }
    },
    //时
    hours(data) {
      if (data) {
        this.secondsTimer3 = setInterval(() => {
          let date;
          let nowTime = new Date().getTime();
          date = new Date(data).getTime();
          var times = (date * 1 - nowTime * 1) / 1000; //time是剩余时间总的秒数
          if (times > 0) {
            var d = parseInt((times / 60 / 60) % 24); //计算时
            this.hoursNum = this.padZero(d);
          } else {
            clearInterval(this.secondsTimer3);
          }
          this.isTime(this.product.beforeBeginTime);
        }, 1000);
      }
    },
    //分
    minutes(data) {
      if (data) {
        this.secondsTimer4 = setInterval(() => {
          let date;
          let nowTime = new Date().getTime();
          date = new Date(data).getTime();
          var times = (date * 1 - nowTime * 1) / 1000; //time是剩余时间总的秒数
          if (times > 0) {
            var d = parseInt((times / 60) % 60); //计算分
            this.minutesNum = this.padZero(d);
          } else {
            clearInterval(this.secondsTimer4);
          }
          this.isTime(this.product.beforeBeginTime);
        }, 1000);
      }
    },
    //秒
    seconds(data) {
      if (data) {
        this.secondsTimer5 = setInterval(() => {
          let date;
          let nowTime = new Date().getTime();
          date = new Date(data).getTime();
          var times = (date * 1 - nowTime * 1) / 1000; //time是剩余时间总的秒数
          if (times > 0) {
            var d = parseInt(times % 60); //计算秒
            this.secondsNum = this.padZero(d);
          } else {
            clearInterval(this.secondsTimer5);
          }
          this.isTime(this.product.beforeBeginTime);
        }, 1000);
      }
    },
    //全民购时间
    isBeginTime(date) {
      let data = new Date().getTime();
      date = new Date(date).getTime();
      if (date <= data) {
        return false;
      } else {
        return true;
      }
    },
    //优先购时间显示
    isTime(date) {
      let data = new Date().getTime();
      date = new Date(date).getTime();
      if (date <= data) {
        return false;
      } else {
        return true;
      }
    },

    back() {
      this.$router.go(-1);
    },

    padZero(num) {
      return num.toString().padStart(2, "0");
    },
  },
};
</script>

<style lang="scss">
.commodityDetails {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/gather.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 90px;
  overflow: auto;
  padding-bottom: 30px;
  &-back {
    position: absolute;
    width: 72px;
    height: 72px;
    top: 66px;
    left: 100px;
  }
  &-back:hover {
    cursor: pointer;
  }

  &-detail {
    width: 1178px;
    height: 587px;
    background: url("https://cdn.bubbleplan.cn/static/home/Group104412x.png")
      no-repeat;
    background-size: 100% 100%;
    padding-left: 52px;
    position: relative;
    &-jiao {
      width: 214px;
      height: 88px;
      position: absolute;
      top: 0px;
      left: 56px;
    }
    &-img {
      width: 482px;
      height: 482px;
    }
    &-number {
      padding: 6px 15px 11px 48px;
      position: absolute;
      background: url("https://cdn.bubbleplan.cn/static/home/number_bgc.png")
        no-repeat;
      background-size: 100% 100%;
      right: 0;
      top: 0;
      color: rgb(255, 255, 255);
      text-align: center;
      -webkit-text-stroke: 1.5px #10061e;
      font-family: "Potta One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // letter-spacing: 2.7px;
    }

    &-commodity {
      margin-top: 124px;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 76.38px;
      &-but {
        width: 582px;
        height: 100px;
        background: url("https://cdn.bubbleplan.cn/static/home/Group104192x.png")
          no-repeat;
        background-size: 100% 100%;
        margin-left: -10px;
        padding: 0 70px;
        align-items: center;
        justify-content: space-between;
        &-price {
          color: rgb(246, 240, 254);
          font-family: "TsangerYuMo";
          font-size: 30px;
          font-weight: bold;
          text-shadow: -3px -3px 0 rgba(82, 32, 146, 1),
            3px -3px 0 rgba(82, 32, 146, 1), -3px 3px 0 rgba(82, 32, 146, 1),
            3px 3px 0 rgba(82, 32, 146, 1);
          /* 模拟描边效果 */
        }
        &-button:hover {
          cursor: pointer;
        }
        &-button {
          width: 465px;
          height: 63px;
          background: url("https://cdn.bubbleplan.cn/static/home/Vector606.png")
            no-repeat;
          background-size: 100% 100%;
          color: rgb(243, 233, 208);
          line-height: 63px;
          text-align: center;
          text-shadow: 0 3.3px 0 rgba(0, 0, 0, 0.25);
          font-family: "TsangerYuMo";
          font-size: 29px;
          font-weight: bold;
        }
      }
      &-unpack {
        color: #cad213;
        font-size: 16px;
      }
      &-unpack:hover {
        cursor: pointer;
      }
      &-box {
        padding-left: 22px;
        padding-right: 107px;
        &-wwe {
          color: rgb(61, 53, 58);
          font-size: 22px;
          font-weight: Bold;
        }
      }
      &-box::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &-title {
        color: #ffffff;
        font-size: 24px;
        font-weight: 700;
        position: absolute;
        top: 16.84px;
        left: 65.48px;
        transform: rotate(-1.752deg);
        color: rgb(254, 245, 222);
        font-family: "TsangerYuMo";
        font-size: 30px;
        font-weight: bold;
        text-shadow: -3px -3px 0 rgba(52, 16, 48, 1),
          3px -3px 0 rgba(52, 16, 48, 1), -3px 3px 0 rgba(52, 16, 48, 1),
          3px 3px 0 rgba(52, 16, 48, 1);
        /* 模拟描边效果 */
      }
      &-price {
        color: #e6ef25;
        font-size: 30px;
        font-weight: 700;
      }
      &-text {
        color: #7b8197;
        font-size: 19px;
        margin-bottom: 17px;
        line-height: 40px;
      }
      &-sellRemark {
        margin-top: 30px;
        width: 100%;
        font-size: 18px;
        color: rgb(61, 53, 58);
      }
      &-sellRemark1 {
        margin-top: 30px;
        max-height: 46px;
        overflow: hidden;
        width: 100%;
        font-size: 18px;
        color: #c0c9de;
      }
    }
    &-time {
      border-top: 1px solid #c0c9de;
      padding: 23px 32px;
      &-t {
        .t-time {
          width: 30.3px;
          height: 30.3px;
          flex-shrink: 0;
          border-radius: 3.45px;
          background: #021521;
          color: #64849a;
          font-size: 17px;
          text-align: center;
          line-height: 30.3px;
        }
        .t-inco {
          font-size: 20px;
          color: rgb(255, 255, 255);
          margin: 0 7px;
          line-height: 31.94px;
        }
      }
      &-preemption {
        color: #000000;
        font-size: 18px;
        font-style: normal;
        text-align: center;
        margin-top: 16.67px;
        width: 283.59px;
        height: 50.27px;
        line-height: 56px;
        background: #e6ef25;
        border-radius: 4px;
      }
      &-preemption:hover {
        cursor: pointer;
      }
    }
  }
  &-text {
    width: 1172px;
    color: #ffffffb3;
    font-size: 15px;
    margin-top: 20px;
    line-height: 25.5px;
    // letter-spacing: 0.45px;
  }
  .emptyw {
    height: 31.96px;
  }
}
</style>
