<template>
  <div class="paymentDetailsBgc">
    <el-dialog
      title=""
      :visible.sync="paymentDetailsShow"
      width="30%"
      :before-close="paymentDetailsCose"
      :show-close="false"
    >
      <div class="paymentDetailsClass">
        <div class="paymentDetailsClass-title">请选择购买方式</div>
        <div class="paymentDetailsClass-type">
          <div
            v-for="(item, index) in payType"
            :key="index"
            class="paymentDetailsClass-type-li flex"
            @click="paymentTypeButton(item)"
          >
            <div style="width: 17px"></div>
            <div v-if="item == 1">
              <span style="color: #da13d2">{{ commodityData.price }}</span
              ><span>rmb购买</span>
            </div>
            <div v-if="item == 2">
              <span style="color: #da13d2">{{ commodityData.price2 }}</span
              ><span>晶体购买</span>
            </div>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/Vector677.png"
              mode=""
              class="paymentDetailsClass-type-li-img"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="30%"
      title=""
      :visible.sync="paymentDetailsTypeShow"
      :before-close="paymentDetailsTypeCose"
      :show-close="false"
    >
      <div class="paymentDetailsTypeClass">
        <div
          class="paymentDetailsTypeClass-title flex items-center flex-between"
        >
          <div style="width: 52.5px"></div>
          <div class="paymentDetailsTypeClass-title-title">确认购买</div>
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/mine/X2x.png"
            mode=""
            class="paymentDetailsTypeClass-title-img"
            @click="paymentDetailsTypeCose()"
          />
        </div>
        <div class="paymentDetailsTypeClass-centre">
          <div
            v-if="paymentType == 1"
            class="paymentDetailsTypeClass-centre-text"
          >
            确认支付<span style="color: #da13d2">{{ commodityData.price }}</span
            >rmb购买
          </div>
          <div
            v-if="paymentType == 2"
            class="paymentDetailsTypeClass-centre-text2 flex"
          >
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/commodity/crystal111.png"
              mode=""
              class="paymentDetailsTypeClass-centre-text2-img"
            />
            <div class="paymentDetailsTypeClass-centre-text2-text">
              <span class="paymentDetailsTypeClass-centre-text2-text-pri"
                >×</span
              >{{ commodityData.price2 }}
            </div>
          </div>
        </div>
        <div class="paymentDetailsTypeClass-button" @click="purchaseButton()">
          购买
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    payType: Array,
    commodityData: Object,
  },
  data() {
    return {
      paymentDetailsTypeShow: false,
      paymentDetailsShow: false,
      paymentType: 1,
    };
  },
  methods: {
    purchaseButton() {
      this.paymentDetailsTypeShow = false;
      this.$emit("purchaseButton", this.paymentType);
    },
    paymentTypeButton(item) {
      this.paymentType = item;
      this.paymentDetailsShow = false;
      this.paymentDetailsTypeShow = true;
    },
    paymentDetailsTypeCose() {
      this.paymentDetailsTypeShow = false;
      this.paymentDetailsShow = true;
    },
    paymentDetailsCose() {
      this.paymentDetailsShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.paymentDetailsBgc {
  :deep(.el-dialog__header) {
    padding: 0 !important;
  }
  :deep(.el-dialog__body) {
    padding: 0 !important;
  }
  :deep(.el-dialog) {
    background: transparent !important;
    box-shadow: none !important;
  }
}
.paymentDetailsClass {
  width: 590px;
  height: 358px;
  background: url("https://cdn.bubbleplan.cn/m_bubble/home/Group10890.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 0 60px 0 80px;
  padding-top: 14px;

  &-title {
    color: #ffffff;
    text-align: center;
    // -webkit-text-stroke-width: 2px;
    // -webkit-text-stroke-color: #7b3eff;
    text-shadow: -1.5px -1.5px 0 #7b3eff, 1.5px -1.5px 0 #7b3eff,
      -1.5px 1.5px 0 #7b3eff, 1.5px 1.5px 0 #7b3eff;
    // /* 模拟描边效果 */
    font-family: "TsangerYuMo1";
    font-size: 32px;
  }

  &-type {
    margin-top: 43px;
    &-li:hover {
      cursor: pointer;
    }
    &-li {
      width: 100%;
      height: 78px;
      background: #f5efe2;
      margin-bottom: 17px;
      color: #3e2b39;
      font-size: 25px;
      font-weight: Bold;
      align-items: center;
      justify-content: space-around;

      &-img {
        width: 15px;
        height: 21px;
      }
    }
  }
}

.paymentDetailsTypeClass {
  width: 497px;
  height: 276px;
  background: url("https://cdn.bubbleplan.cn/m_bubble/home/Group10236.png")
    no-repeat;
  background-size: 100% 100%;

  &-title {
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 44px;
      height: 43px;
      margin-right: -5px;
      margin-top: -6px;
    }

    &-title {
      color: #574352;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 25px;
      font-style: normal;
      font-weight: bold;
      margin-top: 21px;
    }
  }

  &-centre {
    &-text {
      margin-top: 75px;
      font-size: 22px;
      font-weight: Bold;
      text-align: center;
      margin-bottom: 75px;
    }

    &-text2 {
      margin-top: 57.6px;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      &-img {
        width: 80px;
        height: 80px;
      }

      &-text {
        position: relative;
        z-index: 99;
        font-family: "Klipan Black";
        font-size: 25px;
        text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000,
          1px 1px 0 #000000;
        /* 模拟描边效果 */
        color: #ffffff;
        margin-left: 110px;
        margin-top: -20px;

        &-pri {
          font-size: 28rpx;
        }
      }
    }
  }
  &-button:hover {
    cursor: pointer;
  }
  &-button {
    width: 148px;
    height: 54px;
    background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Vect13123or6062x.png")
      no-repeat;
    background-size: 100% 100%;
    margin: auto;
    color: #f3e9d0;
    text-align: center;
    font-family: "TsangerYuMo1";
    font-size: 22px;
    line-height: 54px;
  }
}
</style>